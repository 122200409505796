import React from "react"
import Layout from "../components/Layout"

export default function ClubOwners() {
  return (
    <Layout>
      <section className="section hero is-medium">
        <div className="container hero-body">
          <h1 className="title">Coming Soon</h1>
        </div>
      </section>
    </Layout>
  )
}
